<template>
 <div id="app">
   
  </div>
</template>

<script>
import axios from 'axios';
import { inject } from 'vue';
export default {
  setup() {
    // 接收名为 'message' 的数据
    const url666 = inject('url666');
    const urlBackend = inject('urlBackend');
    const apiToImage = inject('apiToImage');
    return {
      url666,
      urlBackend,
      apiToImage
    };},
  data() {
   
  
    return {
      title:"PDF转图片",
      title2:"把PDF转成图片，输出格式为jpg",
      treeData: [
        {
          id: 1,
          
          label: 'PDF转换',
          children: [
            {
              id: 2,
              
              label: 'PDF转图片'              
            },
            {
              id: 5,
              label: 'PDF转Word'
            },
            {
              id: 6,
              label: 'PDF转Excel'
            },
            {
              id: 7,
              label: 'PDF转PPT'
            },
          ]
        },
        {
          id: 8,
          label: 'PDF工具',
          children: [
            {
              id: 9,
              label: 'PDF拆分'             
            },
            {
              id: 12,
              label: 'PDF合并'
            }
          ]
        }
      ],
      opShow:false,
      show:false,
      tableData: [{            
          }],
      nodeKey:"id",
      fileeData:null,
      
    };
  },
  durl:"",
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    handleClick(row){
      console.log(row)
      window.open(this.durl);
    },
        uploadFile() {
          if(!this.fileData){
            this.$message({
              type: 'error',
              message: "请先选择需要上传的文档"
            });
             return;
          }
          const formData = new FormData();
      formData.append('file', this.fileData);
      
       
       const url = this.urlBackend + this.apiToImage
       this.tableData[0].status="正在处理"
       this.tableData[0].filename=this.fileData.name        
       this.show = true      
       this.opShow = false
      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        // 处理服务器响应
        
        this.durl = this.url666 +response.data
        console.log(this.durl);
        console.log("okkk")
        this.opShow = true
        this.tableData[0].status="转换成功"        
      }).catch(error => {
        // 处理错误
        console.error(error);
        this.tableData[0].status="转换失败，请稍后再试"+error.message
      });
    }
        ,
        onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
       this.fileData = file;
      }
      this.tableData[0].status="已选择"
       this.tableData[0].filename=this.fileData.name 
       this.show = true      
    },
        handleNodeClick(nodeData, node, component) {
      console.log('点击了：', nodeData,node,component);
      this.number="66666";
      
    }
  
      }
    }
      
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* 自定义file类型的input */
  #fileInput {
    display: none;
  }

  .custom-file-upload {
    color: #fff;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-color: #0960bd;
    background: #0960bd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 4px;
  }
  .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* 使用视口高度 */
}

.button-container {
  margin: 0 10px; /* 可以根据需要调整 */
}

.cc {
  margin: 0 10px; /* 可以根据需要调整 */
}

  .custom-file-upload span {
    margin-right: 8px;
  }


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
