<template>
 <div id="app">
    <!-- 左侧列表栏 -->
    <!-- 右侧大的页面 -->
    <div style="float: left; width: calc(100% - 200px);">
      <div>{{title  }}</div>
      <div>{{title2  }}</div>
      <div class ="container">
        
      <div class="cc">
      <label class="custom-file-upload">
      <span>选择文档</span>
      <input id="fileInput" type="file" accept = ".pdf" @change="onFileChange" name="111" value=""/>
      </label>
      </div>
      <div class="button-container">
      <el-button @click="uploadFile" size="medium">开始转换</el-button>
      </div>
      </div>
      
      

      <div style="float: left; width: calc(100% - 100px);">

      <el-table
      v-if="show"
      id="stable"
      :data="tableData"
      :show-header=false
      style="width: 100%">
      <el-table-column
        prop="filename"
        label="文件名"
        width="180">
      </el-table-column>
      <el-table-column
        prop="status"
        label="状态"
        width="180">
      </el-table-column>      
      <el-table-column
      fixed="right"
      label="操作"
      width="100"
      >
      <template v-slot="scope">
        <el-button v-if="opShow" @click="handleClick(scope.row)" type="text" size="small">下载</el-button>        
      </template>
    </el-table-column>

    </el-table>
  </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  data() {
    return {
      title:"PDF转Word",
      title2:"内容不变，转换成word，利用word进行编辑",
      treeData: [
        {
          id: 1,
          
          label: 'PDF转换',
          children: [
            {
              id: 2,
              
              label: 'PDF转图片'              
            },
            {
              id: 5,
              label: 'PDF转Word'
            },
            {
              id: 6,
              label: 'PDF转Excel'
            },
            {
              id: 7,
              label: 'PDF转PPT'
            },
          ]
        },
        {
          id: 8,
          label: 'PDF工具',
          children: [
            {
              id: 9,
              label: 'PDF拆分'             
            },
            {
              id: 12,
              label: 'PDF合并'
            }
          ]
        }
      ],
      opShow:false,
      show:false,
      tableData: [{            
          }],
      nodeKey:"id",
      fileeData:null,
      
    };
  },
  durl:"",
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    handleClick(row){
      console.log(row)
      window.open(this.durl);
    },
        uploadFile() {
          if(!this.fileData){
            this.$message({
              type: 'error',
              message: "请先选择需要上传的文档"
            });
             return;
          }
          const formData = new FormData();
      formData.append('file', this.fileData);
      
       // url = 'http://127.0.0.1:3000/upload'
       const url = 'https://www.pdf666.com:3000/upload'
       this.tableData[0].status="正在处理"
       this.tableData[0].filename=this.fileData.name        
       this.show = true      
       this.opShow = false
      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(response => {
        // 处理服务器响应
        
        this.durl = "https://www.pdf666.com/"+response.data
        console.log(this.durl);
        console.log("okkk")
        this.opShow = true
        this.tableData[0].status="转换成功"        
      }).catch(error => {
        // 处理错误
        console.error(error);
        this.tableData[0].status="转换失败，请稍后再试"+error.message
      });
    }
        ,
        onFileChange(event) {
      const file = event.target.files[0];
      if (file) {
       this.fileData = file;
      }
      this.tableData[0].status="已选择"
       this.tableData[0].filename=this.fileData.name 
       this.show = true      
    },
        handleNodeClick(nodeData, node, component) {
      console.log('点击了：', nodeData,node,component);
      this.number="66666";
      
    }
  
      }
    }
      
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* 自定义file类型的input */
  #fileInput {
    display: none;
  }

  .custom-file-upload {
    color: #fff;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-color: #0960bd;
    background: #0960bd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 4px;
  }
  .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* 使用视口高度 */
}

.button-container {
  margin: 0 10px; /* 可以根据需要调整 */
}

.cc {
  margin: 0 10px; /* 可以根据需要调整 */
}

  .custom-file-upload span {
    margin-right: 8px;
  }


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
