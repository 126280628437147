<template>
 <div id="app">
    <!-- 左侧列表栏 -->
    <div style="width: 200px; float: left;">
      <el-tree
      :data="treeData"
      :default-expand-all="true"
      :expand-on-click-node="true"
      :highlight-current="true"
      :current-node-key="2"
      :node-key="nodeKey"
      @node-click="handleNodeClick"
    ></el-tree>
    </div>
    <!-- 右侧大的页面 -->
    <div style="float: left; width: calc(100% - 200px);">
     <component :is="cvue"></component>
    </div>
  </div>
</template>

<script>

import pdfSplit from './split.vue'
import pdf2Image from './toimage.vue'
import pdf2Word from './toword.vue'
import pdf2Excel from './toexcel.vue'
import pdf2Ppt from './toppt.vue'
import pdfMerge from './merge.vue'
import pdfWaterMark from './watermark.vue'
import empty from './empty.vue'
import {provide} from 'vue'


export default {
  setup() {
    
    provide('url666', 'https://www.pdf666.com');
    provide('urlBackend', 'https://www.pdf666.com:3000');
    provide('apiToImage', '/upload')
  },
  components:{
    pdfSplit,
    pdf2Image,
    pdf2Word,
    pdf2Excel,
    pdf2Ppt,
    pdfMerge,
    pdfWaterMark,
    empty
  },
  data() {
    return {
      cvue:"pdf2Image",
      title:"PDF转图片",
      title2:"把PDF转成图片，输出格式为jpg",
      treeData: [
        {
          id: 1,
          
          label: 'PDF转换',
          children: [
            {
              id: 2,
              
              label: 'PDF转图片'              
            },
            {
              id: 3,
              label: 'PDF转Word'
            },
            {
              id: 4,
              label: 'PDF转Excel'
            },
            {
              id: 5,
              label: 'PDF转PPT'
            },
          ]
        },
        {
          id: 6,
          label: 'PDF工具',
          children: [
            {
              id: 7,
              label: 'PDF拆分'             
            },
            {
              id: 8,
              label: 'PDF合并'
            },
            {
              id: 9,
              label: 'PDF加水印'
            },
          ]
        }
      ],
      opShow:false,
      show:false,
      tableData: [{            
          }],
      nodeKey:"id",
      fileeData:null,
      
    };
  },
  durl:"",
  name: 'HelloWorld',
  props: {
    msg: String
  },
  methods: {
    
        
        handleNodeClick(nodeData, node, component) {
      console.log('点击了：', nodeData,node,component);
      if(nodeData.id == 2){
        this.cvue = "pdf2Image"
      }
      else if(nodeData.id >=3){
        this.$message({
              type: 'info',
              message: "实现中，敬请期待"
            });
        this.cvue = "empty"
      }
      else if(nodeData.id==3){
        this.cvue = "pdf2Word"
      }
      else if(nodeData.id==4){
        this.cvue = "pdf2Excel"
      }
      else if(nodeData.id==5){
        this.cvue = "pdf2Ppt"
      }
      else if(nodeData.id==7){
        this.cvue = "pdfSplit"
      }
      else if(nodeData.id==8){
        this.cvue = "pdfMerge"
      } else if(nodeData.id==9){
        this.cvue = "pdfWaterMark"
      }     
    }
  
      }
    }
      
  
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  /* 自定义file类型的input */
  #fileInput {
    display: none;
  }

  .custom-file-upload {
    color: #fff;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-color: #0960bd;
    background: #0960bd;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    border-radius: 4px;
  }
  .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vh; /* 使用视口高度 */
}

.button-container {
  margin: 0 10px; /* 可以根据需要调整 */
}

.cc {
  margin: 0 10px; /* 可以根据需要调整 */
}

  .custom-file-upload span {
    margin-right: 8px;
  }


h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
